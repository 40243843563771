import React, { lazy } from 'react'
import { useKeycloak } from '@react-keycloak/web'

const Routes = lazy(() => import('./routes/routes'))
const SplashScreen = lazy(() => import('./components/SplashScreen'))

function App(): any {
  const { initialized } = useKeycloak()

  if (!initialized) {
    return <SplashScreen />
  }

  return <Routes />
}

export default App
