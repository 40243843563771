import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import './index.css'

import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Windmill } from '@windmill/react-ui'
import { ToastContainer } from 'react-toastify'
import App from './App'
import keycloak, { keycloakInitConfig } from './providers/keycloak'
import { SidebarProvider } from './context/SidebarContext'
import SplashScreen from './components/SplashScreen'
import theme from './theme/theme'

import 'react-toastify/dist/ReactToastify.css'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakInitConfig}>
    <SidebarProvider>
      <Suspense fallback={<SplashScreen />}>
        <Windmill theme={theme}>
          <ToastContainer />
          <App />
        </Windmill>
      </Suspense>
    </SidebarProvider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function dddd
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
