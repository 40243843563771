import Keycloak from 'keycloak-js'
import { Config } from '../config'

export const keycloakInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
}

export const keycloakEventLogger = (event: unknown, error: unknown): void => {
  // console.log('onKeycloakEvent', event, error)
}

// keycloakTokenLogger(tokens: unknown)
export const keycloakTokenLogger = (tokens: unknown): void => {
  // console.log('onKeycloakTokens', tokens)
}

// Setup Keycloak instance as needed
const { clientId, realm, url } = Config.keycloak

const keycloak = Keycloak({
  clientId,
  realm,
  url,
})

export default keycloak
