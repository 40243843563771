import React from 'react'

import { Player } from '@lottiefiles/react-lottie-player'

function SplashScreen() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex justify-center items-center">
      {/* <h2>Splash screen</h2> */}
      <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/packages/lf20_sE2Em1.json"
        style={{ height: '300px', width: '300px' }}
      />
    </div>
  )
}

export default SplashScreen
